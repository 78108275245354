"use client";

import { useCallback, useMemo, useState, Suspense } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Backdrop,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { graphql } from "@/gql";
import { useQuery } from "@apollo/client";
import dynamic from "next/dynamic";
import { Event } from "@/gql/graphql";
import "./HomePageCompagny.css";
import { useSelectCompagnyContext } from "@/context/CompagnyContext";
import EmptyState from "./EmptyState/EmptyState";
import useAnonymousId from "@/hooks/useAnonymousId";

// Types
const EVENTS = graphql(`
  query GetEventExploredCompagny(
    $take: Int!
    $skip: Int!
    $categoryIds: [Int!]
    $compagnyId: Int
  ) {
    getEventExplored(
      take: $take
      skip: $skip
      categoryIds: $categoryIds
      compagnyId: $compagnyId
    ) {
      count
      events {
        id
        title
        startedDate
        endedDate
        startedTime
        note
        endedTime
        descriptions
        countUserInterestEvent
        currentUserIsInterest
        eventPrices {
          currentUserCurrency
          eventPublicPriceByUser
        }
        coverImage {
          id
          url
        }
        categories {
          id
          name
          coverImage {
            id
            url
          }
        }
      }
    }
  }
`);

const CategoryMenu = dynamic(() => import("./CategoryMenu/CategoryMenu"), {
  loading: () => <Box sx={{ height: 50 }} />,
});

const EventGrid = dynamic(() => import("./EventGrid/EventGrid"), {
  loading: () => <Box sx={{ height: 50 }} />,
});

const HomePageCompagny = () => {
  const { isClient } = useAnonymousId();
  const { compagny, loading: loadingInit } = useSelectCompagnyContext();
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const size = 10;

  const queryVariables = useMemo(
    () => ({
      skip: 0,
      take: size,
      compagnyId: compagny?.id,
      ...(selectedCategory && { categoryIds: [selectedCategory] }),
    }),
    [selectedCategory, compagny]
  );

  const { data, loading, fetchMore, refetch } = useQuery(EVENTS, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
  });

  const hasMore = useMemo(
    () =>
      (data?.getEventExplored?.events?.length ?? 0) <
      (data?.getEventExplored.count ?? 0),
    [data]
  );

  const handleFetchMore = useCallback(() => {
    if (loading || !hasMore) return;

    const currentLength = data?.getEventExplored.events.length || 0;
    fetchMore({
      variables: {
        ...queryVariables,
        skip: currentLength,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getEventExplored: {
            ...fetchMoreResult.getEventExplored,
            events: [
              ...prev.getEventExplored.events,
              ...fetchMoreResult.getEventExplored.events,
            ],
          },
        };
      },
    });
  }, [data, loading, fetchMore, hasMore, queryVariables]);

  const memoizedEvents = useMemo(
    () => data?.getEventExplored?.events || [],
    [data]
  );

  if (loadingInit || !isClient) return null;

  return (
    <Box sx={{ flex: 1, bgcolor: "white", color: "black", p: 2 }}>
      <Container maxWidth="lg" sx={{ minHeight: "80vh" }}>
        <Suspense>
          <CategoryMenu
            isDesktop={isDesktop}
            getSelectedCategory={setSelectedCategory}
          />
        </Suspense>
        <Box sx={{ flex: 1, my: 5 }}>
          {memoizedEvents.length ? (
            <InfiniteScroll
              dataLength={memoizedEvents.length || 0}
              next={handleFetchMore}
              hasMore={Boolean(hasMore)}
              loader={false}
              refreshFunction={refetch}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
            >
              <EventGrid
                events={(memoizedEvents as Event[]) || []}
                primaryColor={compagny.primaryColor}
              />
            </InfiniteScroll>
          ) : (
            !loading && <EmptyState />
          )}
        </Box>

        <Backdrop
          sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
          open={loading}
        >
          <CircularProgress size={30} style={{ color: "white" }} />
        </Backdrop>
      </Container>
    </Box>
  );
};

export default HomePageCompagny;
